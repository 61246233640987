*{
  margin: 0px;
  padding: 0px;
}
.App {
  height: 100vh;
  width: 100vw;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../public/images/Fundo.jpeg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.App #image-logo{
  height: 20%;
  position: absolute;
}

@media (max-width: 460px) {
  .App{
    height: 1fr;
  }
}
